import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex f2-no-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_f2_toolbar = _resolveComponent("f2-toolbar")!
  const _component_f2_pagination = _resolveComponent("f2-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
          key: 0,
          type: "indeterminate"
        }))
      : _createCommentVNode("", true),
    (_ctx.allSeriesUseSameDatasource)
      ? (_openBlock(), _createBlock(_component_f2_toolbar, {
          key: 1,
          definition: _ctx.props.definition,
          "data-source-info": _ctx.dataSourceInfo,
          "selected-items": [],
          onSetpage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pagination?.changePage($event)))
        }, null, 8, ["definition", "data-source-info"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      style: _normalizeStyle({visibility: _ctx.isLoading ? 'hidden' : 'visible'}),
      class: "chart-container"
    }, [
      _createElementVNode("canvas", {
        ref: "canvas",
        style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
        class: _normalizeClass([_ctx.props.definition.resolvedClasses, "chart"])
      }, null, 6)
    ], 4),
    (_ctx.allSeriesUseSameDatasource)
      ? (_openBlock(), _createBlock(_component_f2_pagination, {
          key: 2,
          ref: "pagination",
          "data-source-info": _ctx.dataSourceInfo
        }, null, 8, ["data-source-info"]))
      : _createCommentVNode("", true)
  ]))
}