import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"width":"100%","margin":"6px 0 0 0"} }
const _hoisted_2 = {
  key: 0,
  style: {"display":"flex","flex-direction":"row","align-items":"center"}
}
const _hoisted_3 = ["accept", "multiple", "disabled", "required"]
const _hoisted_4 = {
  key: 0,
  ref: "canvas",
  width: "0",
  height: "0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _directive_f2Src = _resolveDirective("f2Src")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.definition.type === 'file')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ion_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.input.click()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              _createElementVNode("input", _mergeProps({
                ref: "input",
                type: "file",
                accept: _ctx.accept,
                multiple: _ctx.isMulti,
                disabled: _ctx.props.disabled || _ctx.isUploading,
                required: _ctx.isRequired
              }, _ctx.additionalInputAttributes, {
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
              }), null, 16, _hoisted_3)
            ]),
            _: 1
          }),
          (_ctx.isImagePicker && _ctx.platform !== 'web')
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                slot: "end",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openCamera()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: _ctx.cameraOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ion_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSingatureModalOpen = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_modal, {
            "is-open": _ctx.isSingatureModalOpen,
            "backdrop-dismiss": false,
            onDidPresent: _ctx.onSignatureModalReady
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, { slot: "start" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, { onClick: _ctx.confirmSignature }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('action.ok')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('label.signature')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isSingatureModalOpen = false))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('action.cancel')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, { class: "ion-padding" }, {
                default: _withCtx(() => [
                  (_ctx.isSingatureModalOpen)
                    ? (_openBlock(), _createElementBlock("canvas", _hoisted_4, null, 512))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["is-open", "onDidPresent"])
        ], 64)),
    _createVNode(_component_ion_progress_bar, {
      style: _normalizeStyle({visibility: !_ctx.isUploading ? 'hidden' : 'visible'}),
      value: _ctx.uploadProgress
    }, null, 8, ["style", "value"]),
    (_ctx.files.length)
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 2,
          slot: "end",
          lines: "none",
          style: {"padding":"0"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: file.uid,
                class: "files"
              }, {
                default: _withCtx(() => [
                  (file.mime_type.startsWith('image/') || file.mime_type.startsWith('video/') && !file.additional_info?.origin)
                    ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                        key: 0,
                        slot: "start",
                        "router-link": `/files/${file.uid}`
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createVNode(_component_ion_img, null, null, 512), [
                            [
                              _directive_f2Src,
                              file.uid,
                              void 0,
                              { xs: true }
                            ]
                          ])
                        ]),
                        _: 2
                      }, 1032, ["router-link"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(file.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_icon, {
                    slot: "end",
                    icon: _ctx.close,
                    onClick: ($event: any) => (_ctx.removeFile(file))
                  }, null, 8, ["icon", "onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}