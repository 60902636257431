import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_f2_icon = _resolveComponent("f2-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_f2_download = _resolveComponent("f2-download")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.template && _ctx.isTemplateDefined)
      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
          key: 0,
          type: "indeterminate"
        }))
      : _createCommentVNode("", true),
    (_ctx.actionType === 'file')
      ? (_openBlock(), _createBlock(_component_f2_download, {
          key: 1,
          url: _ctx.resultData
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              ref: "button",
              expand: "block",
              style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
              class: _normalizeClass(_ctx.props.definition.resolvedClasses)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_f2_icon, {
                  slot: _ctx.slot,
                  definition: _ctx.props.definition,
                  data: _ctx.data
                }, null, 8, ["slot", "definition", "data"]),
                (_ctx.template)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.template), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["style", "class"])
          ]),
          _: 1
        }, 8, ["url"]))
      : (_openBlock(), _createBlock(_component_ion_button, {
          key: 2,
          ref: "button",
          expand: "block",
          style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
          class: _normalizeClass(_ctx.props.definition.resolvedClasses),
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.executeAction(); $event.stopPropagation();})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_f2_icon, {
              slot: _ctx.slot,
              definition: _ctx.props.definition,
              data: _ctx.data
            }, null, 8, ["slot", "definition", "data"]),
            (_ctx.template)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.template), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["style", "class"]))
  ], 64))
}