import {Capacitor} from '@capacitor/core';
import {alertController} from '@ionic/vue';
import F2Api, {ClientType, LoginInfo, Menu, TokenInfo, UserApplication} from './framework2-sdk/f2Api.js';
import {set} from './framework2-sdk/f2ApiSingleton';
import {getGeoLocation} from './location';
import localization from './localization';
import {showErrorToast} from './functionality/logging';
import {getRandomString} from './helpers';
import CacheHandler from './cacheHandler';
import {setSetting, getSetting, deleteSetting} from './db';

import router from './router';
import {store} from './store';
let api: F2Api | null = null;
let noSessionApi: F2Api | null = null;

export interface Credentials {
	username?: string,
	password?: string,
	oneTimePassword?: string,
	totp?: string,
	tokenInfo?: TokenInfo,
	is2FAEnabled?: boolean,
	applicationKey?: string,
	serverKey?: string,
	baseURL?: string,
	setCookie?: boolean,
    encryptionToken?: {
        public: string,
        private: string
    },
    oldEncryptionPublicToken?: string
}

/**
 * initializes f2 api
 * @param {Credentials} credentials
 * @param {string} language
 * @param {function} selectApplicationFn
 */
export async function init(
    credentials: Credentials,
    language: string = localization.global.locale.value,
    selectApplicationFn: (userApplications: UserApplication[]) => Promise<string> = async (userApplications) => userApplications[0].key,
) {
    try {
        api = new F2Api({
            language,
            baseUrl: credentials.baseURL,
            getGeoLocation: async function(isNeeded: boolean) {
                if (isNeeded) {
                    const position = await getGeoLocation(undefined, false);
                    return position?.coords;
                }
                return null;
            },
            onTokenExpired: async function() {
                if (!api) {
                    console.error(new Error('api not set - get'), 'caught');
                    logout('api not set');
                    return;
                }
                if (credentials.is2FAEnabled) {
                    try {
                        const alert = await alertController.create({
                            buttons: [localization.global.t('submit')],
                            inputs: [
                                {
                                    placeholder: localization.global.t('username'),
                                    attributes: {
                                        required: true,
                                    },
                                },
                                {
                                    placeholder: localization.global.t('authentication code'),
                                    attributes: {
                                        required: true,
                                        maxlength: 6,
                                        minlength: 6,
                                        inputmode: 'numeric',
                                    },
                                },
                            ],
                        });
                        await alert.present();
                        const {data} = await alert.onDidDismiss();
                        await api.loginWith2FA(data.values[0], data.values[1], credentials.applicationKey, credentials.setCookie);
                    } catch (e: any) {
                        logout(e.message);
                        throw e;
                    }
                } else if (credentials.username && credentials.password) {
                    try {
                        await api.login(credentials.username, credentials.password, credentials.applicationKey, credentials.setCookie);
                    } catch (e: any) {
                        logout(e.message);
                        throw e;
                    }
                } else {
                    logout('session expired');
                }
            },
            onTokenExpiredFails: async function(e: Error) {
                console.error(e, 'caught', 'api - onTokenExpiredFails');
                logout('api not set');
            },
            onRequestError: async function(error: any, config: any) {
                if (error.code === 'ERR_NETWORK') {
                    const request = this.cacheHandler?.get(config, true);
                    if (request) {
                        return request;
                    }
                }
                throw error;
            },
        });
        set(api);

        let loginInfo!: LoginInfo;
        const setCookie = credentials.applicationKey ? credentials.setCookie : false;
        if (
            !credentials.is2FAEnabled &&
			credentials.username &&
			credentials.password
        ) {
            if (
                store.state.user.userId &&
                store.state.user.email &&
                store.state.user.applications
            ) {
                loginInfo = {
                    userId: store.state.user.userId,
                    userEmail: store.state.user.email,
                    applicationKey: credentials.applicationKey || 'none',
                    userApplications: store.state.user.applications,
                };
            }
            if (!loginInfo || store.state.app.networtkStatus?.connected) {
                loginInfo = await api.login(credentials.username, credentials.password, credentials.applicationKey, setCookie)
                    .catch((e: any) => {
                        if (loginInfo && e.code === 'ERR_NETWORK') {
                            return loginInfo;
                        }
                        throw e;
                    });
            }
        } else if (credentials.oneTimePassword) {
            loginInfo = await api.loginWithOneTimePassword(credentials.oneTimePassword, credentials.applicationKey, setCookie);
            delete credentials.oneTimePassword;
        } else if (credentials.username && credentials.totp) {
            loginInfo = await api.loginWith2FA(credentials.username, credentials.totp, credentials.applicationKey, setCookie);
            delete credentials.totp;
        } else if (credentials.tokenInfo) {
            api._setToken(credentials.tokenInfo);
            delete credentials.tokenInfo;
            delete credentials.applicationKey;
            loginInfo = {
                // dummy data -> gets filled below in setApplication
                userId: 0,
                userEmail: '',
                applicationKey: '',
                userApplications: await api.getUserApplications(),
            };
        } else {
            throw new Error('Missing creadentials');
        }

        if (!credentials.applicationKey) {
            credentials.applicationKey = await selectApplicationFn(loginInfo.userApplications);
            if (!store.state.app.networtkStatus?.connected) {
                loginInfo.applicationKey = credentials.applicationKey;
            } else {
                loginInfo = await api.setApplication(credentials.applicationKey, credentials.setCookie);
            }
        }
        const cacheHandler = new CacheHandler(credentials.serverKey as string, loginInfo.applicationKey, loginInfo.userId, loginInfo);
        await cacheHandler.init();
        api.cacheHandler = cacheHandler;
        store.commit('app/setCacheActivationStatus', cacheHandler.isActive);
        await initUser(credentials, loginInfo, language);
        return {credentials, loginInfo};
    } catch (e) {
        api = null;
        throw e;
    }
}

/**
 * initializes f2 user
 * @param {Credentials} credentials
 * @param {LoginInfo} loginInfo
 * @param {string} locale
 */
export async function initUser(
    credentials: Credentials,
    loginInfo: LoginInfo,
    locale: string = localization.global.locale.value,
) {
    const api = get() as F2Api;
    const client: ClientType = Capacitor.getPlatform() !== 'web' ? 'mobile' : 'web';
    const application = loginInfo.userApplications.find((a) => a.key === credentials.applicationKey);
    if (credentials.oldEncryptionPublicToken) {
        api.removeEncryptionToken(credentials.oldEncryptionPublicToken);
    }
    const [userInfo, menus, unReadNotificationsCount, encryptionToken] = await Promise.all([
        api.userInfo(),
        api.menus(client),
        api.unreadNotificationsCount(application?.id).catch(() => 0),
        client !== 'web' ?
            credentials.encryptionToken ||
            api.addEncryptionToken(getRandomString(32), getRandomString(32))
                .then((token: any) => ({
                    public: token.public_token as string,
                    private: token.private_token as string,
                }))
                .catch(() => undefined) : undefined,
    ]);
    const pageUID: string = userInfo.roleSettings?.[0]?.start_page_uid || store.state.user.lastPageUID || '';
    credentials.encryptionToken = encryptionToken;

    let menu: Menu | undefined;
    if (store.state.user?.menu?.id) {
        menu = menus.find((m) => m.id === store.state.user.menu.id);
    }
    if (!menu) {
        menu = menus.find((m) => m.id === userInfo.roleSettings?.[0].start_menu_id);
    }
    if (!menu) {
        menu = menus[0];
    }
    const shortcutMenu: Menu | undefined = menus.find((m) => m.id === userInfo.roleSettings?.[0].shortcut_menu_id);
    store.dispatch('user/login', {
        credentials,
        mapKey: userInfo.mapApiKey,
        pageUID,
        application,
        applications: loginInfo.userApplications,
        menu,
        menus,
        shortcutMenu,
        locale,
        email: loginInfo.userEmail,
        userId: loginInfo.userId,
        unReadNotificationsCount,
    });
}

/**
 * gets server config & returns baseURL
 * @param {string} serverKey
 * @param {string} locale
 * @param {F2Api} api
 */
export async function initServerConfig(
    serverKey: string,
    locale: string = localization.global.locale.value,
    api: F2Api = new F2Api({
        language: locale,
    }),
) {
    let config;
    const settingsKey = `server-config-${serverKey}`;
    if (store.state.app.networtkStatus?.connected) {
        config = await api.getServerConfig(serverKey)
            .then(async (serverConfig) => {
                const protocol = serverConfig.useSSL === false ? 'http://' : 'https://';
                const baseURL = protocol + serverConfig.uri_authority;
                const logoURL = serverConfig.logo_uid && `https://ilos.framework2.dev/api/files/${serverConfig.logo_uid}/md`;
                api.setBaseUrl(baseURL);
                const loginInfo = await api.getLoginInfo()
                    .catch(() => ({
                        externalLogins: [],
                        is2FAEnabled: false,
                    }));
                return {
                    serverConfig,
                    result: {
                        baseURL,
                        logoURL,
                        setCookie: serverConfig.setCookie !== false,
                        isFallbackLoginActive: serverConfig.isFallbackLoginActive === true,
                        ...loginInfo,
                    },
                };
            })
            .then(async (config) => {
                await setSetting(settingsKey, JSON.stringify(config))
                    .catch((e) => {
                        console.error('error while storing server config', e);
                        return undefined;
                    });
                return config;
            }, (error) => {
                if (error.response?.data?.message) {
                    showErrorToast(error.response.data.message);
                    throw error;
                } else {
                    return getServerConfigFromDb(settingsKey);
                }
            });
    } else {
        config = await getServerConfigFromDb(settingsKey);
    }
    api.setBaseUrl(config.result.baseURL);
    return config.result;
}

async function getServerConfigFromDb(settingsKey: string) {
    const config = await getSetting(settingsKey);
    if (
        !config ||
        new Date(config.serverConfig.valid_until) <= new Date()
    ) {
        deleteSetting(settingsKey);
        throw new Error(localization.global.t('no network connection'));
    }
    return config;
}

/**
 * initializes f2 api
 * @param {string} baseUrl
 * @param {string} language
 */
export async function initWithoutSession(baseUrl: string, language: string = localization.global.locale.value) {
    try {
        noSessionApi = new F2Api({
            language,
            baseUrl,
        });
        return noSessionApi;
    } catch (e) {
        noSessionApi = null;
        throw e;
    }
}

export function logout(logoutReason?: string) {
    api = null;
    set(api);
    store.dispatch('user/logout', {logoutReason});
    router.replace({name: 'login'});
}

/**
 * gets api
 * @return {any}
 */
export function get() {
    if (api) {
        return api;
    } else {
        console.error(new Error('api not set - get'), 'caught');
        logout('api not set');
    }
}

/**
 * sets the "accept-language" header of the requests
 * @param {string} locale
 */
export function setAPILocale(locale: string) {
    if (api) {
        api.setLanguage(locale);
    }
    if (noSessionApi) {
        noSessionApi.setLanguage(locale);
    }
}
