<template>
    <ion-progress-bar
        v-if="isDownloading"
        :value="progressPercent / 100"
    />
    <div v-else>
        <a
            v-if="objectURL"
            ref="aTagRef"
            :href="objectURL"
            :download="fileName"
        />
        <span @click="triggerDownload">
            <slot />
        </span>
    </div>
</template>
<script lang="ts">
import {defineComponent, ref, watch} from 'vue';
import 	{IonProgressBar} from '@ionic/vue';
import {downloadBlob} from '@/functionality/download';
import {Filesystem, Directory} from '@capacitor/filesystem';
import {FileOpener} from '@capacitor-community/file-opener';
import {Capacitor} from '@capacitor/core';
import {showErrorToast} from '@/functionality/logging';
import {useI18n} from 'vue-i18n';

export default defineComponent({
    components: {
        IonProgressBar,
    },
    props: {
        url: {
            required: true,
            type: String,
        },
    },
    setup(props, context) {
        const {t} = useI18n();

        const objectURL = ref<string | undefined>(undefined);
        const aTagRef = ref<HTMLElement | null>(null);
        let currentATagRef: HTMLElement	| null = null;

        const {
            download,
            blob,
            progressPercent,
            isDownloading,
            error,
            fileName,
            fileMimeType,
        } = downloadBlob(props.url);

        watch(blob, async (b) => {
            if (!b) {
                return;
            }
            if (!isMobileApp()) {
                objectURL.value = window.URL.createObjectURL(b);
            } else {
                try {
                    await storeDataOnMobileDevice(b, fileName.value, fileMimeType.value);
                } catch (e: any) {
                    error.value = e;
                }
            }
        });

        if (!context.slots['default']) {
            triggerDownload();
        }

        watch(aTagRef, (aRef) => {
            currentATagRef = aRef;
            triggerDownload();
        });

        watch(error, (e) => {
            if (!e) {
                return;
            }
            showErrorToast(t('error.loadingFile'));
            console.error('file download error', e);
        });

        async function triggerDownload() {
            download();
            if (!isMobileApp()) {
                if (!currentATagRef) {
                    return;
                }
                currentATagRef.click();
            } else {
                if (!blob.value) {
                    return;
                }
                try {
                    await storeDataOnMobileDevice(blob.value, fileName.value, fileMimeType.value);
                } catch (e: any) {
                    error.value = e;
                }
            }
        }

        return {
            objectURL,
            progressPercent,
            isDownloading,
            error,
            aTagRef,
            fileName,
            triggerDownload,
        };
    },
});

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve((reader.result as string).split(',')[1]);
        reader.readAsDataURL(blob);
    });
}

async function storeDataOnMobileDevice(blob: Blob, fileName: string, fileMimeType: string) {
    const base64Data = await blobToBase64(blob);
    await Filesystem.deleteFile({
        path: fileName,
        directory: Directory.Data,
    }).catch(() => null);
    const fileInfo = await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Data,
        recursive: true,
    });
    await FileOpener.open({
        filePath: fileInfo.uri,
        contentType: fileMimeType,
    });
}

function isMobileApp() {
    return Capacitor.getPlatform() !== 'web';
}

</script>
