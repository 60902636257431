import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "ion-text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jeep_sqlite = _resolveComponent("jeep-sqlite")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_f2_icon = _resolveComponent("f2-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.platform === 'web')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_jeep_sqlite)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_loading, { "is-open": _ctx.isLoading }, null, 8, ["is-open"]),
      _createVNode(_component_ion_split_pane, {
        when: "(min-width: 3850px)",
        "content-id": "main-content"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_ion_menu, {
            side: "end",
            "content-id": "main-content",
            type: "overlay"
          }, {
            default: _withCtx(() => [
              (_ctx.store.state.app.isSetupDone)
                ? (_openBlock(), _createBlock(_component_ion_content, {
                    key: 0,
                    class: "ion-padding-horizontal"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_toolbar, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_select, {
                                        value: _ctx.store.state.user.locale,
                                        "ok-text": "Okay",
                                        "cancel-text": _ctx.$t('action.cancel'),
                                        class: "ion-float-left",
                                        onIonChange: _ctx.setLanguage
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.user?.application?.locales || _ctx.DEFAULT_LOCALES, (locale) => {
                                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                                              key: locale.language_code,
                                              value: locale.language_code
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(locale.name), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["value"]))
                                          }), 128))
                                        ]),
                                        _: 1
                                      }, 8, ["value", "cancel-text", "onIonChange"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_col, { style: {"flex-grow":"0"} }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_button, {
                                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.store.commit('user/isDarkMode', !_ctx.store.state.user?.isDarkMode)))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            icon: _ctx.store.state.user?.isDarkMode ? _ctx.sunnyOutline : _ctx.moonOutline
                                          }, null, 8, ["icon"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_col, { class: "ion-text-right" }, {
                                    default: _withCtx(() => [
                                      (_ctx.store.state.user.isLoggedIn)
                                        ? (_openBlock(), _createBlock(_component_ion_button, {
                                            key: 0,
                                            onClick: _ctx.signOut
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t('signOut')) + "  ", 1),
                                              _createVNode(_component_ion_icon, { icon: _ctx.logOutOutline }, null, 8, ["icon"])
                                            ]),
                                            _: 1
                                          }, 8, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_row, { class: "ion-margin-bottom ion-nowrap" }, {
                                default: _withCtx(() => [
                                  (_ctx.store.state.user.isLoggedIn)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createVNode(_component_ion_col, { class: "ion-align-self-center ion-text-right ion-padding-end" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  "router-direction": "root",
                                                  "router-link": "/profile"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_title, { class: "ion-no-padding" }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.store.state.user.credentials.username), 1)
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                                            _createVNode(_component_ion_button, { onClick: _ctx.changeApplication }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.store.state.user.application.name) + "  ", 1),
                                                _createVNode(_component_ion_icon, { icon: _ctx.chevronForward }, null, 8, ["icon"])
                                              ]),
                                              _: 1
                                            }, 8, ["onClick"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_col, { style: {"flex-grow":"0"} }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_avatar, {
                                                  "router-direction": "root",
                                                  "router-link": "/profile"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("img", {
                                                      src: _ctx.store.getters['user/gravatar'](64)
                                                    }, null, 8, _hoisted_2)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ], 64))
                                    : (_openBlock(), _createBlock(_component_ion_col, { key: 1 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_title, {
                                            size: "large",
                                            class: "ion-text-right ion-padding-bottom"
                                          }, {
                                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                                              _createTextVNode(" F2 ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.store.state.user.isLoggedIn)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_ion_row, { class: "ion-margin-top" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, { style: {"flex-grow":"0"} }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_button, { "router-link": "/notifications" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, { icon: _ctx.notificationsOutline }, null, 8, ["icon"]),
                                            (_ctx.store.state.user?.unReadNotificationsCount)
                                              ? (_openBlock(), _createBlock(_component_ion_badge, {
                                                  key: 0,
                                                  color: "danger"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.store.state.user?.unReadNotificationsCount), 1)
                                                  ]),
                                                  _: 1
                                                }))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_select, {
                                      value: _ctx.store.state.user.menu?.id,
                                      "ok-text": "Okay",
                                      "cancel-text": _ctx.$t('action.cancel'),
                                      justify: "end",
                                      onIonChange: _ctx.setMenu
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.user.menus, (menu) => {
                                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                                            key: menu.id,
                                            value: menu.id
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(menu.title_text_overwrite || menu.title_text), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["value", "cancel-text", "onIonChange"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_list, {
                              id: "inbox-list",
                              style: {"clear":"both"}
                            }, {
                              default: _withCtx(() => [
                                (_ctx.store.state.user.isMenuItemsLoading)
                                  ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                                      key: 0,
                                      type: "indeterminate"
                                    }))
                                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.store.state.user.menuItems, (menuItem) => {
                                      return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                                        key: menuItem.id,
                                        "auto-hide": false
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_item, {
                                            "router-direction": "root",
                                            "router-link": '/pages/' + menuItem.page_uid,
                                            lines: "none",
                                            detail: false,
                                            class: _normalizeClass(["hydrated", { selected: _ctx.route.params.id === menuItem.page_uid }])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(menuItem.title_text_overwrite || menuItem.title_text), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_f2_icon, {
                                                slot: "end",
                                                definition: menuItem
                                              }, null, 8, ["definition"])
                                            ]),
                                            _: 2
                                          }, 1032, ["router-link", "class"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                              ]),
                              _: 1
                            })
                          ], 64))
                        : (_ctx.showFallbackLogin && !('forcePasswordLogin' in _ctx.router.currentRoute.value.query))
                          ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_menu_toggle, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, {
                                      "router-direction": "root",
                                      "router-link": "/login?forcePasswordLogin"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('login with password')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.appVersion)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("small", null, _toDisplayString(_ctx.appVersion), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.route.name !== 'setup']
          ]),
          _createVNode(_component_ion_router_outlet, {
            id: "main-content",
            ref: "presentingElement"
          }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        ref: "applicationsModal",
        "can-dismiss": _ctx.isApplicationsModalDismissable,
        "is-open": _ctx.isApplicationsModalOpen,
        "presenting-element": _ctx.presentingElement,
        "enter-animation": _ctx.enterModalAnimation,
        "leave-animation": _ctx.leaveModalAnimation,
        onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isApplicationsModalOpen = false))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('applications')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_ion_list, { class: "ion-padding-horizontal" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userApplications, (application) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: application.key,
                    color: _ctx.store.state.user.isLoggedIn && _ctx.store.state.user.application.key === application.key ? 'primary' : undefined,
                    href: "javascript:void(0)",
                    onClick: ($event: any) => (_ctx.selectApplication(application))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                        default: _withCtx(() => [
                          (application.logo_uid)
                            ? (_openBlock(), _createBlock(_component_ion_img, {
                                key: 0,
                                src: `${_ctx.baseURL}/api/files/${application.logo_uid}/sm`
                              }, null, 8, ["src"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(application.name), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["color", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["can-dismiss", "is-open", "presenting-element", "enter-animation", "leave-animation"])
    ]),
    _: 1
  }))
}