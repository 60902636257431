import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f2_toolbar = _resolveComponent("f2-toolbar")!
  const _component_data_source_status = _resolveComponent("data-source-status")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_f2_pagination = _resolveComponent("f2-pagination")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
    class: _normalizeClass(_ctx.props.definition.resolvedClasses)
  }, [
    _createVNode(_component_f2_toolbar, {
      definition: _ctx.props.definition,
      "data-source-info": _ctx.dataSourceInfo,
      "selected-items": _ctx.selectedItems,
      onSetpage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pagination.changePage($event)))
    }, null, 8, ["definition", "data-source-info", "selected-items"]),
    _createVNode(_component_data_source_status, { "data-source-info": _ctx.dataSourceInfo }, null, 8, ["data-source-info"]),
    (_ctx.isLoading || !_ctx.isMarkersReady)
      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
          key: 0,
          type: "indeterminate"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "map-container",
      style: _normalizeStyle({visibility: !_ctx.isMarkersReady ? 'hidden' : 'visible'})
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { id: "map" }, null, -1)
    ]), 4),
    _createVNode(_component_f2_pagination, {
      ref: "pagination",
      "data-source-info": _ctx.dataSourceInfo
    }, null, 8, ["data-source-info"])
  ], 6))
}